import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { ourClient } from "@utils/static-data";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

import clientStyles from "@cssmodules/client.module.scss";

export default function Cient() {
    const [activeAbjad, setActiveAbjad] = useState({ index: 0 });

    const renderAbjadButton = () => {
        const abcd = Object.keys(ourClient);
        const sortedAbcd = [];

        if (abcd.length > 0) {
            for (let i = 0; i < abcd.length; i += 8) {
                sortedAbcd.push(abcd.slice(i, i + 8));
            }
        }

        return sortedAbcd.map((group, keys) => (
            <div key={keys} className={clientStyles.buttonContainer}>
                {group.map((abc, key) => (
                    <Button
                        className={`${clientStyles.button} text-uppercase ${
                            keys * 8 + key === activeAbjad.index
                                ? clientStyles.active
                                : ""
                        } `}
                        key={key}
                        onClick={() =>
                            setActiveAbjad({ index: keys * 8 + key })
                        }
                    >
                        {abc}
                    </Button>
                ))}
            </div>
        ));
    };

    const renderClientName = () => {
        const abcd = Object.keys(ourClient);
        const list = [...ourClient[abcd[activeAbjad.index]]];
        const half = Math.ceil(list.length / 2);

        let firstHalf = list.splice(0, half);
        let secondHalf = list.splice(-half);

        return (
            <Row>
                <Col md={12} lg={6}>
                    {firstHalf.map((client, key) => (
                        <p className={clientStyles.clientText} key={key}>
                            {client}
                        </p>
                    ))}
                </Col>
                <Col md={12} lg={6}>
                    {secondHalf.map((client, key) => (
                        <p className={clientStyles.clientText} key={key}>
                            {client}
                        </p>
                    ))}
                </Col>
            </Row>
        );
    };

    return (
        <Layout>
            <main id="main-content" className={clientStyles.main}>
                <SEO title="Klien Kami" />
                <h2 className={clientStyles.header}>
                    Daftar nama Klien yang order di Konveksirompi.com sesuai
                    abjad
                </h2>
                <section>
                    <Container>
                        <Row>
                            <Col
                                xs={12}
                                lg={5}
                                className="d-flex align-items-center justify-content-center"
                                style={{ flexDirection: "column" }}
                            >
                                {renderAbjadButton()}
                            </Col>
                            <Col xs={12} lg={7}>
                                <Card className={`${clientStyles.card} h-100`}>
                                    <Card.Body
                                        className={clientStyles.cardBody}
                                    >
                                        {renderClientName()}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </Layout>
    );
}
